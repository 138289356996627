
import { defineComponent, onMounted, reactive } from "vue";
import * as moment from "moment";
import { ElNotification } from 'element-plus'

import GalerieImage from "@/views/still/deploy/galerieImage.vue";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Affichage d'un ticket",
  emits: ["reloadTicket"],
  props: {
    objTicket : {
      default: {"tic_knum_societe": "", "tic_c_label": "", "tic_c_serv": [], "tic_list_matos": [],"tic_staging": []},
    },
    modeTic : {
      default: 0,
    },
  },
  components: {
    GalerieImage,
  },
  setup(props, { emit }) {
    moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      objTicket: props.objTicket as any,
      modeTic: props.modeTic as any,
      mAdresse: [] as any,
      activeStep : 0,
      stagingOnOff: 0,
    });
   
    onMounted(async () => {
      //console.log('OBJ', state.objTicket);
      let count = 0;
      state.objTicket.tic_c_serv.forEach(async (val) => { 

        val.old_tis_notes = val.tis_notes;

        if (val.knum_lieu && val.code) await returnAdresse(val.code, val.knum_lieu, val.knum_contact);
        count += 1;

        // if (val.tis_status != 'En cours') state.activeStep += 1;
        if (getStatusA(val.code) == 1 ) state.activeStep += 1;


        if( count == state.objTicket.tic_c_serv.length) state.loaderEnabled = false;
      });
      
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const byCode = (code) => {
      if (state.objTicket) return state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.code == code)]
    }

    function isJSON(str) {
        try {
            return (JSON.parse(str) && !!str);
        } catch (e) {
            return false;
        }
    }

    const reloadTicket = () => {
      emit("reloadTicket", 1);
    };

    const delFile = (tif_seq,tis_seq) => {
      const form = {
       tif_knum_ticket: state.objTicket.tic_seq,
       tif_knum_ticket_services: tis_seq,
        tif_seq: tif_seq,
      }

      mAxiosApi
        .post("/delFileTicketService", form)
        .then( async() => {
            emit("reloadTicket", 1);
        })
    };

    const updateCom = (tis_seq, newCom) => {
      const form = {
       ticSeq: state.objTicket.tic_seq,
       tisSeq: tis_seq,
       newCom: newCom,
      }

      mAxiosApi
        .post("/updateComTicketService", form)
        .then( async() => {
          emit("reloadTicket", 1);
          ElNotification({
            title: 'Succès',
            message: 'Commentaires modifié',
            type: 'success',
          })

        })
    };
     

    const returnFilesCode = (code) => {
      if (!state.objTicket) return false;
      const fichiers = state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.code == code)].fichiers;

      if (isJSON(fichiers)) {
        if (JSON.parse(fichiers).length > 0) {
          let filesArray = JSON.parse(fichiers).filter(el => el.name.indexOf('.jpg') <= 0);
          filesArray = filesArray.filter(el => el.name.indexOf('.png') <= 0);
          filesArray = filesArray.filter(el => el.name.indexOf('.gif') <= 0);
          return filesArray;
        }  
        return false;
      } else {
        if (fichiers.length > 0) return fichiers;
        return false;
      }
    }

    const returnImgCode = (code) => {
      if (!state.objTicket) return false;
      const fichiers = state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.code == code)].fichiers;

      if (isJSON(fichiers)) {
        if (JSON.parse(fichiers).length > 0) {
          let filesArray = JSON.parse(fichiers).filter(el => el.name.indexOf('.jpg') > 0 || el.name.indexOf('.png') > 0 || el.name.indexOf('.gif') > 0);
          return filesArray;
        }  
        return false;
      } else {
        if (fichiers.length > 0) return fichiers;
        return false;
      }
    }

    const byCodeAdr = (code) => {
      if (state.mAdresse) return state.mAdresse[state.mAdresse.findIndex(element => element.code == code)]
    }
    const returnAdresse = async (code, adr_seq, con_seq) => {
      let mAdresse = await getAxios("/getAdresseContact/" + adr_seq + '/' + con_seq);
      state.mAdresse.push({ code: code, adresse: mAdresse.records } )
    }

    const getStatus = (code) => {
      /*
       if (!state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status) return '';
      return state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status;
      */
      if (!state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status) return '';
      return state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status;
    }

    const getStatusA = (code) => {
      /*
       if (!state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status) return '';
      return state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status;
      */
      if (!state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status) return -1;
      const catStatus = state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].cat_status;
      const arrayCat = JSON.parse(catStatus);
      //console.log(arrayCat.status.find(element => element.label==state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status).next);
      
      return arrayCat.status.find(element => element.label==state.objTicket.tic_c_serv[state.objTicket.tic_c_serv.findIndex(element => element.xCheckUser == true && element.code == code)].tis_status).next;
    }


    const downloadFile = async (item) => {
      

      let myList = await getAxios("/getFileTicket/" + item.id + '/' + item.name);

      const data = encodeURI(myList.results[0].tif_base64);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", item.name);
      link.click();

    };


    return {
      state,
      byCode,
      formatDate,
      returnAdresse,
      byCodeAdr,
      getStatus,
      getStatusA,
      downloadFile,
      returnFilesCode,
      returnImgCode,
      reloadTicket,
      delFile,
      updateCom
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}
