<template>
    <div class="mb-5" v-if="state.loaderEnabled">
        <div class='mt-7 mb-3'><b>Image(s) jointe(s) :</b></div>

            <div style="display: flex; flex-direction: row;  flex-wrap: wrap;">
                <template v-for="(src, index) in state.listImage" :key="index">
                  <div class="">
                    <div class="pictTicket" @click="() => showImg(index)" >
                        <img :src="src.data" class="el-image__inner" style="object-fit: cover;"  />
                    </div>
                    
                    <div v-if="state.modeTic">


                      <el-popconfirm
                        confirm-button-text="Oui, la supprimer !"
                        cancel-button-text="Non"
                        icon-color="primary"
                        title="Opération irréversible, voulez-vous supprimer cette image ?"
                        @confirm="delImage(src.id)"
                      >
                        <template #reference>
                          <a  class="btn btn-sm btn-light-danger" style=" margin: 10px; width:150px"><i class="las la-trash fs-2 me-2"></i>Supprimer</a>
                        </template>
                      </el-popconfirm>


                      
                    </div>

                  </div>
                </template>
            </div>
            <vue-easy-lightbox :visible="visibleRef" :imgs="state.listImageV" :index="indexRef" @hide="onHide"></vue-easy-lightbox>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive,ref } from "vue";
import * as moment from "moment";

import mAxiosApi from "@/api";

import VueEasyLightbox from 'vue-easy-lightbox'
//import 'vue-easy-lightbox/external-css/vue-easy-lightbox.css'


export default defineComponent({
  name: "Affichage d'un ticket",
  emits: ["reloadTicket"],
  props: {
    listImage : {},
    modeTic : {
      default: 0,
    },
    tis_seq : {
      default: 0,
    },
    tic_seq : {
      default: 0,
    },
  },
  components: {
    VueEasyLightbox
  },
  setup(props, { emit }) {
    moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: false,
      listImage: [] as any,
      listImageV: [] as any,
      listImageP: props.listImage as any,
      modeTic : props.modeTic,
      tic_seq : props.tic_seq,
      tis_seq : props.tis_seq,
    });

    const visibleRef = ref(false)
    const indexRef = ref(0)
       
    onMounted(async () => {
      state.listImageP.forEach(async el => {

        let myList = await getAxios("/getFileTicket/" + el.id + '/' + el.name);
        el.data = encodeURI(myList.results[0].tif_base64);
        state.listImage.push({data:el.data, id: el.id});
        state.listImageV.push(el.data);
        state.loaderEnabled = true;
        //state.etapes.push({code:myElement.code, title: myElement ? myElement.name : '', desc: 'Service spécifique', visible : true});
      });
      //state.listImage =  state.listImageP;

    });

    const showImg = (index) => {
        indexRef.value = index
        visibleRef.value = true
    }
    const onHide = () => visibleRef.value = false

    const delImage = (tif_seq) => {
      const form = {
        tif_knum_ticket: state.tic_seq,
        tif_knum_ticket_services: state.tis_seq,
        tif_seq: tif_seq,
      }

      mAxiosApi
        .post("/delFileTicketService", form)
        .then( async() => {
            emit("reloadTicket", 1);
        })
    };

    return {
      state,
      visibleRef,
      indexRef,
      showImg,
      onHide,
      delImage
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}
</script>
